import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { initialExportGroupRequest, pollExportGroupRequest } from 'api/export'
import * as actions from 'actions'
import { connect } from 'react-redux'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import StudyTable from './StudyTable'
import Content from '@components/Content'
import { paths } from 'routes'
import { navigate } from 'gatsby'
import TabBar from 'molecules/TabBar'
import { cacheDuration } from '../../../environment'

const PREFIX = 'StudyContent'

const classes = {
	root: `${PREFIX}-root`,
	roleContainer: `${PREFIX}-roleContainer`,
	tabs: `${PREFIX}-tabs`,
	expand: `${PREFIX}-expand`,
	inlineTab: `${PREFIX}-inlineTab`,
	distinguishedTab: `${PREFIX}-distinguishedTab`,
	tabsBackground: `${PREFIX}-tabsBackground`,
	tabPanel: `${PREFIX}-tabPanel`,
	titleBar: `${PREFIX}-titleBar`,
}

const Root = styled('div')(({ theme }) => ({
	[`&.${classes.root}`]: {
		padding: theme.spacing(4),
	},

	[`& .${classes.roleContainer}`]: {
		paddingTop: theme.spacing(4),
	},

	[`& .${classes.expand}`]: {
		flexGrow: 1,
		padding: 0,
		// marginBottom: theme.spacing(1),
	},

	[`& .${classes.inlineTab}`]: {},

	[`& .${classes.distinguishedTab}`]: {
		margin: 15,
		borderColor: 'white',
		borderWidth: 1,
		borderStyle: 'solid',
	},

	[`& .${classes.tabsBackground}`]: {
		minWidth: 190,
	},

	[`& .${classes.tabPanel}`]: {
		overflow: 'auto',
	},

	[`& .${classes.titleBar}`]: {
		padding: theme.spacing(1),
		borderRadius: 10,
	},
}))

let exportJobHolder //page cache

const StudyContent = ({
	location,
	auth,
	patient,
	getStudies,
	setCurrentPatient,
	fetchPatientPicture,
	getPatientData,
	onChatPatient,
}) => {
	const studies = patient.studies || []
	const [currentTab, setCurrentTab] = useState()
	const [currentStudy, setCurrentStudy] = useState(studies.length ? studies[0] : null)
	const [studyNames, setStudyNames] = useState([])

	const [exportButtonText, setExportButtonText] = useState(exportJobHolder ? 'Processing...' : 'Export data')
	const [exportJob, setExportJob] = useState(exportJobHolder)

	const onTabChange = (newValue, tabName) => {
		const current = studies.find((p) => {
			return p.name === tabName
		})
		setCurrentStudy(current)
		setCurrentTab(tabName)
	}

	useEffect(() => {
		if (patient.lastFetched + cacheDuration <= new Date().getTime() && !patient.loading.read) {
			getStudies()
		}
	}, [patient.lastFetched])

	useEffect(() => {
		let interval = null
		if (exportJob && exportJob.status) {
			interval = setInterval(async () => {
				if (exportJob.status !== 'finished') {
					const update = await pollExportGroupRequest(exportJob.type, exportJob.exportUuid)
					exportJobHolder = update
					setExportJob(update)
					setExportButtonText('Processing...')
				}
				return false
			}, 5000)
		}
		return () => clearInterval(interval)
	}, [exportJob])

	useEffect(() => {
		if (exportJob && exportJob.status === 'finished') {
			setExportButtonText('Download')
		}
	}, [exportJob])

	useEffect(() => {
		const names = studies.map((study, i) => {
			return study?.name
		})
		setStudyNames(names)
	}, [studies.length])

	return (
		<Root className={classes.root}>
			<AppBar className={classes.titleBar} position="static" color="default" elevation={0}>
				<Toolbar>
					<Grid container direction="row" justifyContent="flex-end">
						<Grid item xs>
							<Typography variant="h5" color="textSecondary" component="h5">
								Active Studies
							</Typography>
						</Grid>
						<Grid item>
							{studies[currentTab] && studies[currentTab].members.length ? (
								<Button
									className={classes.button}
									variant="outlined"
									color="inherit"
									size="small"
									onClick={async () => {
										if (!exportJob) {
											setExportButtonText('Requesting...')
											const data = await initialExportGroupRequest(studies[currentTab].name)
											exportJobHolder = data
											setExportJob(data)
										} else if (exportJob.status === 'finished') {
											const a = document.createElement('a')
											a.href = exportJob.output
											a.setAttribute('download', 'export.zip')
											a.click()
										}
										return false
									}}
								>
									{exportButtonText}
								</Button>
							) : (
								<></>
							)}
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>

			<AppBar position="static" color="primary">
				<TabBar
					tabNames={studyNames}
					tab={currentTab}
					onTabChange={onTabChange}
					textColor="primary"
					indicatorColor="primary"
					elevation={1}
				/>
			</AppBar>
			<Grid container className={classes.tabPanel}>
				<div className={classes.expand}>
					{currentStudy.members?.length ? (
						<StudyTable
							patient={currentStudy.members}
							setCurrentPatient={setCurrentPatient}
							fetchPatientPicture={fetchPatientPicture}
							onSetCurrentPatient={() => {
								navigate(paths.diary)
							}}
							onChatPatient={(patient, SB) => {
								onChatPatient(patient, SB)
								navigate(paths.chat)
							}}
						/>
					) : (
						<Content
							title={'No people are enrolled in your study!'}
							subtitle={'Have your patents enroll via the Dieta App'}
						/>
					)}
				</div>
			</Grid>
		</Root>
	)
}

StudyContent.propTypes = {
	location: PropTypes.object,
	auth: PropTypes.shape({}),
	patient: PropTypes.shape({
		current: PropTypes.shape({
			id: PropTypes.number,
		}),
		data: PropTypes.array,
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}),
	getStudies: PropTypes.func.isRequired,
	setCurrentPatient: PropTypes.func.isRequired,
	getPatientData: PropTypes.func.isRequired,
	fetchPatientPicture: PropTypes.func.isRequired,
	onChatPatient: PropTypes.func.isRequired,
}

const mapStateToProps = ({ auth, patient }) => {
	return {
		auth,
		patient,
	}
}

export default connect(mapStateToProps, actions)(StudyContent)
