import React from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'

import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import RefreshIcon from '@mui/icons-material/Refresh'
import IconButton from '@mui/material/IconButton'

import StudyContent from 'pages/StudyContent'
import PrivateRoute from 'utils/PrivateRoute'
import { withTranslation } from 'react-i18next'
import { LinearProgress } from '@components'

const PREFIX = 'study'

const classes = {
	secondaryBar: `${PREFIX}-secondaryBar`,
	button: `${PREFIX}-button`,
}

const StyledPrivateRoute = styled(PrivateRoute)(({ theme }) => ({
	[`& .${classes.secondaryBar}`]: {
		zIndex: 0,
	},

	[`& .${classes.button}`]: {
		borderColor: lightColor,
	},
}))

const lightColor = 'rgba(255, 255, 255, 0.7)'

function Study({ t, location, pageContext, patient, getStudies }) {
	const title = t(`${pageContext.titleKey}.title`)
	return (
		<>
			<AppBar component="div" className={classes.secondaryBar} color="primary" position="static" elevation={0}>
				<Toolbar>
					<Grid container alignItems="center" spacing={2}>
						<Grid item xs>
							<Typography style={{ textTransform: 'capitalize' }} color="inherit" variant="h5">
								{title}
							</Typography>
						</Grid>
					</Grid>
					<Grid item xs />
					<Grid item>
						<Tooltip arrow title="Reload" aria-label="reload">
							<IconButton
								color="inherit"
								onClick={() => {
									getStudies()
								}}
								size="large"
							>
								<RefreshIcon />
							</IconButton>
						</Tooltip>
					</Grid>
				</Toolbar>
			</AppBar>
			<LinearProgress visible={patient.loading.read} color="primary" />
			<StudyContent location={location} />
		</>
	)
}

Study.propTypes = {
	location: PropTypes.object,
	patient: PropTypes.shape({
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}),
	getStudies: PropTypes.func.isRequired,
}

const StudyPage = ({ location, ...rest }) => {
	return <StyledPrivateRoute location={location} component={Study} {...rest} />
}

const mapStateToProps = ({ patient }) => {
	return {
		patient,
	}
}
export default compose(connect(mapStateToProps, actions), withTranslation())(StudyPage)
